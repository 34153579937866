// FREE PLAN REMOVED FROM PRODUCT (04.01.2022)

import { graphql, StaticQuery } from "gatsby"
import parse from 'html-react-parser'
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useInView } from "react-intersection-observer"
import Callout from "../../../assets/images/pricing-callout.svg"
import Buttons from "../../../components/common/button"
// import ThreeTestimonialS from "../../../components/careers/three-testimonials"
import Frame from "../../../components/common/frame"
import Navigation from "../../../components/navigation"
import Features from "../../../components/pricing/solo-features"
import Questions from "../../../components/pricing/solo-questions"
import CTAThankYou from "../../../components/utility/cta_thank_you"
import Slide from "../../../components/utility/slide_content"
// import Testimonials from "../../../components/home/testimonials"

function PricingSolo() {
  const [refTestimonial, inViewTestimonial] = useInView({
    triggerOnce: true,
    threshold: 0,
  })

  // useEffect(() => {
  //   document.querySelectorAll(
  //     ".solo-cta .cta-box p code"
  //   )[0].onclick = function(e) {
  //     if (typeof window.Intercom != "undefined") {
  //       window.Intercom("show")
  //     }
  //   }
  // })

  // useEffect(() => {
  //   document.querySelectorAll(
  //     ".solo-cta .cta-box p code"
  //   )[0].onclick = function(e) {
  //     if (typeof window.Intercom != "undefined") {
  //       window.Intercom("show")
  //     }
  //   }
  // })

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentPricingSolo {
            SuperOps {
              pages(where: { title: "Pricing Solo" }) {
                title
                content {
                  html
                }
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              peopleList(where: { page: "Home" }, orderBy: order_ASC) {
                name
                title
                testimonial
                background
                cardBgColor
                thumbnail {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 110 } }
                    }
                  )
                }
              }
            }
          }
        `}
        render={data => (
          <div className="pricing-wrap">
            {data.SuperOps.pages.map(page => {
              const { content, pageBlock, navigationBlock, seo } = page
              return (
                <>
                  <Frame
                    seo={seo}
                  >
                    <header>
                      <Navigation
                        links={navigationBlock}
                        page="About us"
                        color={`#F1F8FF`}
                      />
                    </header>

                    <section className="main">
                      <div className="solo-hero">
                        <center>
                          <Container>
                            <div className="inner">
                              <Row>
                                <Col lg={6}>
                                  {parse(pageBlock[0].content.html)}
                                  <span className="p16">
                                    {parse(pageBlock[1].content.html)}
                                  </span>
                                  <div className="mt-4">
                                    <Buttons
                                      theme="primary"
                                      arrow
                                      link="/signup"
                                      text="GET STARTED FOR FREE"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <center>
                                    <img
                                      src="https://media.graphcms.com/yt8RADJQYWrffE6PDEUq"
                                      alt="img"
                                    />
                                  </center>
                                </Col>
                              </Row>
                            </div>
                          </Container>
                        </center>
                      </div>

                      <div className="solo-content">
                        <Container>
                          <Row>
                            <Col lg={4}>
                              {" "}
                              <Slide delay="200">
                                {parse(pageBlock[2].content.html)}
                              </Slide>{" "}
                            </Col>

                            <Col lg={8}>
                              {" "}
                              <Slide delay="200">
                                {parse(pageBlock[3].content.html)}
                              </Slide>{" "}
                            </Col>
                          </Row>
                        </Container>
                      </div>

                      <div className="solo-testimonials" ref={refTestimonial}>
                        <Container>
                          {/* <Testimonials
                            heading={parse(
                              pageBlock[18].content.text
                            )}
                            showTestimonial={true}
                            items={data.SuperOps.peopleList}
                          /> */}
                        </Container>
                      </div>

                      <div className="solo-content rich-content">
                        <Container>
                          <Slide delay="200">
                            <Row>
                              <Col lg={4}>
                                {parse(pageBlock[4].content.html)}
                                {parse(pageBlock[5].content.html)}
                              </Col>
                              <Col lg={8}>{parse(content.html)}</Col>
                            </Row>
                          </Slide>
                        </Container>
                      </div>

                      <div className="solo-questions">
                        <Container>
                          <center>
                            {parse(pageBlock[17].content.html)}
                          </center>
                          <div className="column-container">
                            <Questions />
                          </div>
                        </Container>
                      </div>

                      <div className="solo-explain">
                        <Container>
                          <Slide delay="200">
                            <div className="inner">
                              <Row>
                                <Col lg={4}>
                                  <div className="cover">
                                    <span className="info">
                                      {parse(
                                        pageBlock[6].content.html
                                      )}
                                    </span>
                                    <span className="plan">
                                      {parse(
                                        pageBlock[7].content.html
                                      )}
                                    </span>
                                    <span className="feature">
                                      {parse(
                                        pageBlock[8].content.html
                                      )}
                                    </span>
                                    <div className="cost prelative">
                                      {parse(
                                        pageBlock[9].content.html
                                      )}
                                      <img
                                        src={Callout}
                                        className="pricing-callout pricing-co-solo"
                                        alt="img"
                                      />
                                    </div>

                                    <div className="floater">
                                      <div className="left">
                                        {parse(
                                          pageBlock[10].content.html
                                        )}
                                      </div>

                                      <div className="right">
                                        {parse(
                                          pageBlock[11].content.html
                                        )}
                                      </div>
                                    </div>

                                    <div className="line" />
                                    <div className="mt-3">
                                      <Buttons
                                        theme="primary w-100"
                                        arrow
                                        link="/signup"
                                        text="GET STARTED FOR FREE"
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col lg={8} className="pricing-solo-exp-right">
                                  <span className="lques">
                                    {parse(
                                      pageBlock[12].content.html
                                    )}
                                  </span>
                                  <span className="linfo">
                                    {parse(
                                      pageBlock[13].content.html
                                    )}
                                  </span>

                                  <Features />

                                  <span className="llast">
                                    {parse(
                                      pageBlock[14].content.html
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </Slide>
                        </Container>
                      </div>

                      <div className="solo-cta">
                        <Container>
                          {/* <CTABox
                            theme="dark-plain"
                            heading={parse(
                              pageBlock[15].content.text
                            )}
                            description={parse(
                              pageBlock[16].content.html
                            )}
                            maxDescriptionWidth={"416px"}
                          /> */}
                        </Container>
                      </div>
                      {/* <ThreeTestimonialS
                        peopleList={data.SuperOps.peopleList}
                      /> */}
                    </section>
                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default PricingSolo
