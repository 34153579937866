import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row } from "react-bootstrap"

function SoloFeatures() {
  return (
    <>
      <StaticQuery
        query={graphql`
          query soloFeatures {
            SuperOps {
              pricingLists(where: { planName: "solo" }) {
                featureLists {
                  id
                  name
                  parentName
                  parentSlug
                  features
                  featuresSlug
                  featuresDescription
                }
              }
            }
          }
        `}
        render={data => (
          <Row>
            {data.SuperOps.pricingLists.map((pl, plin) => {
              const { featureLists } = pl

              return (
                <>
                  {featureLists.map(fl => {
                    return (
                      <div>
                        <span className="altV2"><h4 className="solo-feature-parent">{fl.parentName}</h4></span>

                        <div className="column-container solo-feature-container">
                          {fl.features.map((f, i) => {
                            return (
                              <div className="column-3 column-br-2 column-sml-1 solo-feature-item p14">
                                <p>
                                  <span>&#8226;</span> {f}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )
                  })}
                </>
              )
            })}
          </Row>
        )}
      />
    </>
  )
}

export default SoloFeatures
