import React from "react"
import { StaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import Slide from "../utility/slide_content"

function soloQuestions() {
  return (
    <>
      <StaticQuery
        query={graphql`
          query pricingSoloBlock {
            SuperOps {
              reasons(where: { page: "Pricing Solo" }) {
                name
                content {
                  html
                }
                badge {
                  url
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.reasons.map(reason => {
              const { name, content, badge } = reason

              return (
                <div className="column-2 column-m-2 column-s-1 mt24">
                  <Slide delay="200" className="h100">
                    <div className="solo-ques-item position-relative">
                      <img src={badge.url} className="position-absolute" alt="img" />
                      <div className="solo-ques-item-ryt">
                        <h2> {name} </h2>

                        <span className="p14">{parse(content.html)}</span>
                      </div>
                    </div>
                  </Slide>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default soloQuestions
